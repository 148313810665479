import { SUPPORTED_LANGUAGES } from 'constants/translations';
import { cloneElement, ReactElement } from 'react';

export const getCurrentLang = () => {
  const lang = localStorage.getItem("lang") || (navigator.language || (navigator as any).userLanguage)?.slice(0, 2);

  return SUPPORTED_LANGUAGES.includes(lang) ? lang : SUPPORTED_LANGUAGES[0];
};

export const replaceTagsWithComponents = (originalString: string, props: Record<string, ReactElement>): (string | ReactElement)[] => {
  const input = originalString.replace(/\\\\/g, '\\');
  const tagRegex = /<(\w+)>(.*?)<\/\1>/g;

  const result: (string | ReactElement)[] = [];
  let lastIndex = 0;

  let match: RegExpExecArray | null;

  // Перебираем все совпадения
  while ((match = tagRegex.exec(input)) !== null) {
    const [fullMatch, tagName, content] = match;
    const startIndex = match.index;

    // Добавляем текст до текущего тега
    if (lastIndex < startIndex) {
      result.push(input.slice(lastIndex, startIndex));
    }

    // Проверяем, есть ли соответствующий React-компонент для текущего тега
    const Component = props[tagName];
    if (Component) {
      result.push(cloneElement(Component, {}, content));
    } else {
      // Если компонента нет, оставляем оригинальный тег
      result.push(fullMatch);
    }

    lastIndex = startIndex + fullMatch.length;
  }

  // Добавляем оставшийся текст после последнего совпадения
  if (lastIndex < input.length) {
    result.push(input.slice(lastIndex));
  }

  return result;
};
