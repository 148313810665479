import { FC, PropsWithChildren, useEffect, useState } from "react";
import TranslationService from "services/TranslationService";

export const TranslateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    TranslationService.initialize().then(() => {
      setInitialized(true);
    });
  }, []);

  if (!initialized) return null;

  return <>{children}</>;
};
