import { StreamTypes } from "types/streams";
import { Stream } from "models/Stream";
import { prettifyOrderId } from "utils/orders";

export const getStreamType = (stream) => {
  switch (true) {
    case !!stream.online?.end_time:
      return StreamTypes.Finished;
    case !!stream.online:
      return StreamTypes.Live;
    case !!stream.schedule:
      return StreamTypes.Schedule;
    default:
      return StreamTypes.Private;
  }
};

export const getStreamerIdModified = (id: string) => {
  return `${id}_`;
};

export const getStreamTitle = (translate: any, stream: Stream): string => {
  return stream?.orderId ?
    translate("orders.orderStreamTitle", { orderId: prettifyOrderId(stream.orderId) }) :
    (stream?.title || "");
}
