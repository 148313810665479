import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { store } from "store";
import type { RootState, AppDispatch } from "store";

export { useDebounce } from "./useDebounce";
export { useLocation } from "./useLocation";
export { useTabs } from "./useTabs";
export { useNavigate } from "./useNavigate";
export { useSubscription } from "./useSubscription";
export { useRecordStreams } from "./useRecordStreams";
export { useAdaptive } from "./useAdaptive";
export { usePopover } from "./usePopover";
export { useRateStreamer } from "./useRateStreamer";
export { useUsers } from "./useUsers";
export { useTimer } from "./useTimer";
export { useInput } from "./useInput";
export { useModals } from "./useModals";
export { useReverseTimer } from "./useReverseTimer";
export { useTopUsers } from "./useTopUsers";
export { useLiveStreams } from "./useLiveStreams";
export { useScheduledStreams } from "./useScheduledStreams";
export { useSubscriptionLiveStreams } from "./useSubscriptionLiveStreams";
export { useSubscriptionScheduledStreams } from "./useSubscriptionScheduledStreams";
export { useUserStreams } from "./useUserStreams";
export { useStreamerStreams } from "./useStreamerStreams";
export { useViewerStreams } from "./useViewerStreams";
export { useAuth } from "./useAuth";
export { useChatInfo } from "./useChatInfo";
export { useStreamFrame } from "./useStreamFrame";
export { useBalance } from "./useBalance";
export { useAppLocationHistory } from "./useAppLocationHistory";
export { useStreamerFinishedStreams } from "./useStreamerFinishedStreams";
export { useViewerFinishedStreams } from "./useViewerFinishedStreams";
export { useStreamRTM } from "./rtm";
export { useWS } from "./ws/useWS";
export { useOAuth2 } from "./useOAuth2";
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dispatchFromService = async (action) => {
  await store.dispatch(action);
};
export { useSoundMessageNotifications } from "./useSoundMessageNotifications";
export { useHire } from "./useHire";
export * from "./translation";
export * from "./useOnboarding";
