import dayjs from "dayjs";
import { OwnerPosition, StreamCategories, StreamEndReasons, StreamRawData, StreamStatus, StreamTypes } from "types/streams";
import { convertCentsToDollars } from "utils/money";
import { CommissionFunds } from "types/transaction";

export class Stream {
  id: string;
  title: string;
  description: string;
  category: StreamCategories;
  avatarURL: string | null;
  userAvatarURL: string | null;
  priceInDollars: number;
  priceFunds: CommissionFunds;
  ownerUserName: string;
  streamerId: string;
  streamId: string;
  status: StreamStatus;
  isPrivate: boolean;
  isPurchased: boolean;
  bio: string | null;
  skills: Array<string> | null;
  type: StreamTypes;
  activeViewers: number;
  totalViewers: number;
  streamStartTime: number; // unix time
  publishingTime: number; // unix time
  endTime: number | null; // unix time
  endReason: StreamEndReasons | null;
  stars: number;
  reviewCount: number;
  subscribersCount: number;
  orderId: string | null;
  orderCount: number;
  rate: number;
  lastStreamCategory: StreamCategories;
  givenStars?: number;
  chatRoomId?: string;
  ownerPosition?: OwnerPosition | null;
  ownerOrganizationAvatarURL?: string | null;
  ownerOrganizationName?: string | null;
  duration?: number | null;
  finishedTime?: number;
  source?: string;
  uniqueViewers?: number;

  // TODO: get rid of deprecated fields
  constructor(data: StreamRawData, streamType?: StreamTypes) {
    const price = data.price_funds?.gross ?? data.stream.price_funds?.gross;
    const type =
      streamType ||
      (data.status === StreamStatus.READY
        ? StreamTypes.Schedule
        : data.status === StreamStatus.ONLINE
        ? StreamTypes.Live
        : data.status === StreamStatus.OFFLINE
        ? StreamTypes.Finished
        : StreamTypes.Live);

    this.id = data.id || data.stream.id;
    this.title = data.title || data.stream.title;
    this.description = data.description || data.stream?.desc || "";
    this.category = data.category || data.stream.category;
    this.priceInDollars = price ? convertCentsToDollars(price) : 0;
    this.priceFunds = data.price_funds;
    this.isPrivate = !!data.stream?.private || data.private || false;
    this.isPurchased = data.purchased ?? data.stream?.purchased ?? !price;
    this.ownerUserName = data.owner_info?.nickname ?? data.owner?.username;
    this.streamerId = data.owner_info?.id || data.owner?.id;
    this.bio = data.owner?.bio || null;
    this.skills = data.owner?.skills || null;
    this.stars = data.owner?.stars || 0;
    this.reviewCount = data.owner?.review_num || 0;
    this.subscribersCount = data.owner?.subscriber_num || data.owner_info?.subscriber_num || 0;
    this.orderCount = data.owner?.order_num || 0;
    this.userAvatarURL = (data.owner_info?.avatar ?? data.owner?.avatar_url) || null;
    this.lastStreamCategory = data.owner?.last_stream_category;
    this.rate = data.owner?.rate || 0;
    this.type = type;
    this.givenStars = data.given_stars || data.stream?.given_stars || 0;
    this.chatRoomId = data.chat_room_id || "";
    this.ownerPosition = data.owner_position ? { lat: data.owner_position.lat, lng: data.owner_position.lon } : null;
    this.ownerOrganizationAvatarURL = data.owner?.organisation?.avatar_url || null;
    this.ownerOrganizationName = data.owner?.organisation?.username || null;
    this.avatarURL = data.preview_url || data.stream?.preview_url;
    this.duration = data.schedule_info?.duration_sec || data.schedule?.duration_sec || dayjs(data.ended_at).diff(data.started_at) || null;
    this.streamStartTime = (data.schedule_info?.start_at ? Date.parse(data.schedule_info.start_at) : data.schedule?.start_unix_time) || 0;
    this.endTime = (data.ended_at ? Date.parse(data.ended_at) : null) || (data.online?.end_time ?? 0);
    this.endReason = data.online?.end_reason || null;
    this.orderId = data.schedule?.order_id || data.schedule_info?.order_id || null;
    this.status = data.status;

    if (type === StreamTypes.Live) {
      this.streamId = data.id || data.online?.stream_id;
      this.activeViewers = data.online?.active_viewers || data.active_viewers || 0;
      this.totalViewers = data.online?.total_viewers || 0;
      this.publishingTime = (data.started_at ? Date.parse(data.started_at) : data.online?.publishing_time) || 0;
    } else if (type === StreamTypes.Schedule) {
      this.streamId = data.id || data.schedule?.stream_id;
    } else if (type === StreamTypes.Finished) {
      this.streamId = data.id;
      this.publishingTime = data.online?.publishing_time || data.schedule?.start_unix_time || 0;
      this.finishedTime = (data.ended_at ? Date.parse(data.ended_at) : null) || (data.online?.end_time ?? 0);
      this.source = data.source ?? "";
      this.uniqueViewers = data.unique_viewers ?? 0;
    }
  }
}

export type StreamList = Array<Stream>;
export type StreamTokenResponse = { rtc_token: string; rtm_token: string };
