import { useTranslation } from "react-i18next";
import { ReactElement } from 'react';
import { replaceTagsWithComponents } from "utils/translations";

export const useTranslate = () => {
  const { t } = useTranslation();

  const translate = (key: string, options?: Record<string, string | ReactElement>): any => {
    const result = replaceTagsWithComponents(t(key, options), options as Record<string, ReactElement>);

    return result.length === 1 ?
      replaceTagsWithComponents(t(key, options), options as Record<string, ReactElement>)[0] :
      <>{replaceTagsWithComponents(t(key, options), options as Record<string, ReactElement>)}</>;
  }

  return { translate };
};
