import { SUPPORTED_LANGUAGES } from "constants/translations";
import { action, makeObservable, observable, runInAction } from "mobx";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentLang } from "utils/translations";

class TranslationService {
  @observable public currentLang: string = getCurrentLang();

  constructor() {
    makeObservable(this);
  }

  @action
  public initialize = async () => {
    if (!i18n.isInitialized) {
      const resources = await Promise.all(
        SUPPORTED_LANGUAGES.map(async (lang) => {
          const translation = require(`locales/${lang}.json`);

          return { [lang]: { translation } };
        })
      ).then((translations) =>
        translations.reduce((acc, cur) => ({ ...acc, ...cur }), {})
      );

      i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: this.currentLang,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
        keySeparator: ".",
      });
    }
  }

  @action
  public changeLanguage = (lang: string) => {
    if (SUPPORTED_LANGUAGES.includes(lang)) {
      i18n.changeLanguage(lang);

      localStorage.setItem("lang", lang);

      runInAction(() => {
        this.currentLang = lang;
      });
    } else {
      console.warn("Unrecognized language:", lang);
    }
  };
}

export default new TranslationService();
